<template>
  <div>
    <div id='ArticleBody' class="content content-block content-bg corner-radius-all article-block article_body" :class="{hidden: !isLoaded, entry: isLoaded}">
      <div class="label-title-content" v-html="article.title.rendered"/>
      <span class="label-small header-date">
        <font-awesome-icon :icon="['far', 'calendar-alt']"/> {{ArticleDate}}
      </span>
      <hr/>
      <div class="label-normal article" v-html="article.content.rendered"/>
    </div>
    <div id="ArticleFooter">
      <router-link to="/blogs">返回到列表</router-link>
    </div>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {ref, computed, onMounted} from 'vue'
import blogManager from '../manager/BlogManager'
export default {
  name: 'BlogArticle',
  setup(props, context) {
    const route = useRoute()
    const currentArticleId = ref(0)
    let article = ref({title: {rendered: ''}, content: {rendered: ''}, date: '1999/12/31T00:00:00'})
    const isLoaded = ref(false)
    
    const ArticleDate = computed(() => {
      return new Date(article.value.date).toLocaleString()
    })

    onMounted(() => {
      blogManager.getArticle(route.params.post_id, (success, obj) => {
        if (success) {
          console.log(obj)
          article.value = obj
        }
        else {
        console.error("[DEBUG] Get blog error: " + obj)
        }
        isLoaded.value = true
      })
    })

    return {
      isLoaded,
      article,
      ArticleDate
    }
  }
}

</script>

<style lang="less" scoped>
@import '../../theme/darkzero.less';
.article_body {
  display: inline-block;
  margin: 1rem 0;
}
.article p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hidden {
  opacity: 0;
  transform: translateX(-120px) scale(1, 1);
}
.entry {
  opacity: 1;
  transform: translateX(0) scale(1, 1);
  transition: opacity 0.75s ease-out 0.5s,
              transform 0.75s ease-out 0.5s;
}
</style>