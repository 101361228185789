<template>
  <div class="content content-block content-bg corner-radius-all article-block">
    <div ref="block-header" class="header">
      <span class="label-title-content header-title" v-html="article.title.rendered"/>
      <span class="label-small header-date">
        <font-awesome-icon :icon="['far', 'calendar-alt']"/> {{ArticleDate}}
      </span>
    </div>
    <hr/>
    <span v-html="ArticleSummary" class="label-normal"/>
    <div class="article-footer">
      <router-link :to="ArticleUrl">
        <font-awesome-icon :icon="['fas', 'angle-right']"/> 全文...
      </router-link>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
export default {
  name: 'ArticleBlock',
  props: {
    article: {type: Object, required: true},
    index: {type: Number, required: true}
  },
  setup(props, context) {
    // values
    
    // computed values
    const ArticleSummary = computed(() => {
      return props.article.content.rendered.split('<!--more-->')[0]
    })
    const ArticleUrl = computed(() => {
      return '/blogs/' + props.article.id
    })
    const ArticleDate = computed(() => {
      return new Date(props.article.date).toLocaleString()
    })
    
    return {
      ArticleSummary,
      ArticleUrl,
      ArticleDate
    }
  }
}
</script>

<style scoped lang="less">
@import '../../theme/darkzero.less';
div.article-block {
  margin: 1rem 0;
  .header {
    display: inline-block;
    width: 100%;
  }
  .header-title {
    display: inline-block;
    position: relative;
    left: 0px;
  }
  .header-date {
    display: block;
    position: relative;
    left: 0px;
    color: @text-color-secondary;
  }
  // 
  .article-footer {
    display: inline-block;
    width: 100%;
    font-size: @font-size-small;
    color: @text-color-secondary;
    text-align: right;
  }
  code {
    overflow: scroll;
  }
}
</style>