<template>
  <div id='BlogList' :class="{hidden: !isLoaded, entry: isLoaded}">
    <ArticleBlock v-for="(article, idx) in articles" 
      :key="article.id" :article="article" :index="idx"/>
  </div>
</template>

<script>
import { ref } from 'vue'
import Axios from 'axios'
import ArticleBlock from './ArticleBlock'
import blogManager from '../manager/BlogManager.js'

export default {
  name: 'BlogList',
  components: {
    ArticleBlock
  },
  setup() {
    const currentPageIndex = ref(1)
    const isLoaded = ref(false)
    const articles = ref([])

    // Get list
    blogManager.getArticleList(currentPageIndex.value, "view", (success, obj) => {
      if (success) {
        console.log(obj)
        articles.value = obj
      }
      else {
        // show error
        console.error("[DEBUG] Get blog list error: " + obj)
      }
      isLoaded.value = true
    })

    return {
      isLoaded,
      articles
    }
  }
}
</script>

<style lang="less" scoped>
.hidden {
  opacity: 0;
  transform: translateX(-120px) scale(1, 1);
}
.entry {
  opacity: 1;
  transform: translateX(0) scale(1, 1);
  transition: opacity 0.75s ease-out 0.5s,
              transform 0.75s ease-out 0.5s;
}
</style>
