import Axios from 'axios'
// class
class BlogManager{
  BlogApiBaseUrl = process.env.VUE_APP_BLOG_API_URL //'http://darkzero.me/blog/wp-json/wp/v2/'
  testFun() {
    console.log('Test Function')
  }

  // Get Article List
  getArticleList(pageIndex, context, callback) {
    Axios.get(this.BlogApiBaseUrl + 'posts?per_page=7&page=' + pageIndex + '&context=' + context)
    .then(response => {
      console.log(response.data)
      callback(true, response.data)
    })
    .catch(error => {
      callback(false, error.response)
    }).finally(() => {
      // nothing
    })
  }

  // Get Article by Id
  getArticle(articleId, callback) {
    Axios.get(this.BlogApiBaseUrl + 'posts/' + articleId)
    .then(response => {
      callback(true, response.data)
    })
    .catch(error => {
      console.log(error)
      callback(false, error.response)
    })
  }
}

let blogManager = new BlogManager()
export default blogManager