<template>
  <!-- single page -->
  <div id="BlogMain" class="blog-body">
    <router-view/>
  </div>
</template>

<script>
import Axios from 'axios'
import BlogList from './BlogList.vue'
export default {
  name: 'BlogMain',
  props: {
    isThumbnail: Boolean
  },
  data() {
    return {
      BlogApiBaseUrl: process.env.VUE_APP_BLOG_API_URL,
      isLoading: false
    }
  },
  components: {
    BlogList
  },
  created: function () {
    console.log('BlogMain created ' + this.isThumbnail)
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
div.blog-body {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  margin: auto;
  max-width: 900px;
}
</style>
